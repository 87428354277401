import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled, Theme } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material';

interface InfoButtonProps {
  text: string;
  size?: 'small' | 'medium' | 'large';
  buttonSize?: number;
  iconSize?: number;
}

export function InfoButton({
  text,
  size = 'small',
  buttonSize = 30,
  iconSize = 18,
}: InfoButtonProps) {
  return (
    <IconButton size={size} sx={{ width: buttonSize, height: buttonSize }}>
      <CustomWidthTooltip
        title={text}
        slots={{
          transition: Zoom,
        }}
      >
        <InfoOutlinedIcon fontSize={size} sx={{ width: iconSize, height: iconSize }} />
      </CustomWidthTooltip>
    </IconButton>
  );
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    fontSize: '0.8rem',
    backgroundColor: (t: Theme) => t.palette.background.default,
    color: (t: Theme) => t.palette.text.primary,
    boxShadow: 5,
  },
});
