/* eslint-disable react/jsx-curly-brace-presence,react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import magicfeedback from "@magicfeedback/native";

// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {useTranslation} from "react-i18next";
import {SURVEY_ID, PUBLIC_KEY} from "src/config-global";
import {ConfirmDialogProps} from './types';
import './sdk.css'
import {useSnackbar} from "../snackbar";


// ----------------------------------------------------------------------

export default function FeedbackDialog({
                                         open,
                                         onClose,
                                       }: ConfirmDialogProps) {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formId = 'a9586820-cfce-11ee-b708-4b55b6371bbf';
  const publicKey = '133f4a4ba360560f0e38823e0202dd58';

  const [form, setForm] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);

  const sdkConfig = {
    addButton: false,
    beforeSubmitEvent: () => {
      console.log("BEFORE")
      setSendLoading(true);
    },
    afterSubmitEvent: (res: any) => {
      console.log("AFTER", res);
      setSendLoading(false);
      if (res.error) {
        enqueueSnackbar(res.error.message || t('error.tryAgain'), {variant: 'error'});
      } else if (onClose) {
        enqueueSnackbar(t('success.feedback'), {variant: 'success'});
        onClose()
      }
    },
    onLoadedEvent: (response: any) => {
      setLoading(false);
    }
  };


  useEffect(() => {
    magicfeedback.init({debug: false});
    setForm(magicfeedback.form(formId, publicKey));
  }, []);

  useEffect(() => {
    if (form && open) {
      try {
        form.generate('magicfeedback', sdkConfig);
      } catch (e) {
        console.error('e', e);
      }
    } else {
      setLoading(true);
    }
  }, [form, open]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogContent sx={{typography: 'body2'}}>
        {loading && (
          <Stack spacing={1} p={4}>
            <Skeleton variant="text" sx={{fontSize: '1rem'}} width='50%'/>
            <Skeleton variant="rectangular" width="100%" height={60}/>
          </Stack>
        )}
        <div id="magicfeedback" style={{padding: '16px'}}/>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent='space-between' spacing={3}>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {t('text.buttons.close')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => form?.send()}
            disabled={loading}
            loading={sendLoading}
          >
            {t('text.buttons.send')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
