import axios, { endpoints } from '../utils/axios';
import getHeader from './header';
import Company from '../models/company';
import CompanyProduct from '../models/companyProduct';
import { clearFilterToSend } from '../components/dataTable/utils';
import { CompanySettings } from '../models/companySettings';

export async function getCompanies(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'name ASC',
  filters?: any,
  fields?: any
): Promise<Company[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: { companyId, ...clearFilterToSend(filters, 'companies') },
      order: order || 'name ASC',
      fields,
    };

    const response = await axios.get(
      `${endpoints.companies.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompaniesCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();

    const filter = { companyId, ...clearFilterToSend(filters, 'companies') };

    const response = await axios.get(
      `${endpoints.companies.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data.count;
    }
    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export async function postCompany(data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.post(`${endpoints.companies.list}`, data, header);

    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchCompany(companyId: string, data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.companies.id(companyId)}`, data, header);

    return response.status > 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getCompanyProducts(companyId: string): Promise<string[]> {
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.companies.products(companyId)}`, header);

    if (response.status === 200 && response.data) {
      const options: string[] = [];
      response.data?.forEach((item: CompanyProduct) => {
        // Add categories in options and check if exist or not
        item.categories?.forEach((category: string) => {
          if (!options.includes(category)) {
            options.push(category);
          }
        });
      });

      // Order options desc
      options.sort((a, b) => a.localeCompare(b));
      return options;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function postCompanyLogo(companyId: string, newFile: File): Promise<string> {
  try {
    const header = await getHeader();
    header.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('file', newFile);

    const response = await axios.post(`${endpoints.companies.logo(companyId)}`, formData, header);

    if (response.status === 200) {
      return response.data.url;
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

export async function postCompanyFavIcon(companyId: string, newFile: File): Promise<string> {
  try {
    const header = await getHeader();
    header.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('file', newFile);

    const response = await axios.post(
      `${endpoints.companies.favIcon(companyId)}`,
      formData,
      header
    );

    if (response.status === 200) {
      return response.data.url;
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

// get company by user
export async function getCompanyByUser(
  userId: string,
  filters: any,
  order?: string
): Promise<Company[]> {
  try {
    const header = await getHeader();

    const filter = {
      where: { ...clearFilterToSend(filters, 'companies') },
      order: order || 'name ASC',
    };

    const response = await axios.get(
      `${endpoints.users.companies(userId)}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get settings
export async function getSettings(companyId: string): Promise<CompanySettings | null> {
  try {
    const header = await getHeader();

    const response = await axios.get(`${endpoints.companies.settings(companyId)}`, header);

    if (response.status === 200) {
      return response.data?.length > 0 ? response.data[0] : null;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}
