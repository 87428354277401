// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
// auth
import { AuthConsumer, AuthProvider } from 'src/auth/context/firebase';
import { FeedbackDrawer, FeedbackProvider } from 'src/components/feedback';
import { DataProvider } from 'src/components/data/context';
import { LocalizationProvider } from './locales';

import './App.css';
import { SnackbarProvider } from './components/snackbar';
import DataDrawer from './components/data/drawer';

/* i18n translation variables */
import './assets/i18n/i18n';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'bold', // 'default' | 'bold'
            themeLayout: 'mini', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
            pathfix: null,
          }}
        >
          <FeedbackProvider>
            <DataProvider>
              <ThemeProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    <SettingsDrawer />
                    <FeedbackDrawer />
                    <DataDrawer />
                    <AuthConsumer>
                      <Router />
                    </AuthConsumer>
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </DataProvider>
          </FeedbackProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
