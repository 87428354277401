import axios, { endpoints } from '../utils/axios';
import getHeader from './header';
import { clearFilterToSend } from '../components/dataTable/utils';

export async function postSignIn(token: string) {
  try {
    const header = await getHeader();
    const body = {
      firebaseTokenId: token,
    };
    const response = await axios.post(endpoints.auth.login, body, header);

    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getUserMe() {
  try {
    const header = await getHeader();
    const response = await axios.get(endpoints.auth.me, header);

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getUsers(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'name DESC',
  filters?: any
) {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: { companyId, ...clearFilterToSend(filters, 'users') },
      order: order || 'name DESC',
    };

    const response = await axios.get(
      `${endpoints.users.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getUsersCount(companyId: string, filters?: any) {
  try {
    const header = await getHeader();
    const filter = { companyId, ...clearFilterToSend(filters, 'users') };
    const response = await axios.get(
      `${endpoints.users.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data.count;
    }
    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export async function postUser(data: any): Promise<boolean | string> {
  try {
    const header = await getHeader();
    const response = await axios.post(`${endpoints.users.list}`, data, header);

    return response.status === 200;
  } catch (e) {
    console.error(e);
    return e?.error?.message || false;
  }
}

export async function patchUser(id: string, data: any): Promise<boolean> {
  try {
    const header = await getHeader();
    const response = await axios.patch(`${endpoints.users.id(id)}`, data, header);

    return response.status > 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}
