import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { InputLabel, Typography } from '@mui/material';
//
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../iconify';
import Image from '../image';
//
import { UploadProps } from './types';
import RejectionFiles from './errors-rejection-files';

// ----------------------------------------------------------------------

export default function UploadAvatar({
  error,
  file,
  disabled,
  placeholder,
  helperText,
  maxSize = 1048576,
  usePreviews = true,
  loading = false,
  sx,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    maxFiles: 1,
    maxSize,
    accept: {
      'image/*': [],
    },
    ...other,
  });

  const hasFile = !!file;

  const hasError = isDragReject || !!error;

  const renderPreview = file && (
    <Image
      alt="img-upload"
      src={typeof file === 'string' ? file : URL.createObjectURL(file)}
      sx={{
        zIndex: 10,
        borderRadius: 1,
        objectFit: 'contain',
        position: 'absolute',
        transition: 'opacity 0.3s',
        height: '100%',
      }}
    />
  );

  const renderPlaceholder = (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      className="upload-placeholder"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        borderRadius: 1,
        position: 'absolute',
        color: 'text.disabled',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        '&:hover': {
          opacity: 0.72,
        },
        ...(hasError && {
          color: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }),
        ...(hasFile && {
          zIndex: 9,
          opacity: 0,
          color: 'common.white',
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
        }),
      }}
    >
      <Iconify icon="eva:cloud-upload-fill" width="30px" />
    </Stack>
  );

  const renderContent = usePreviews && (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        borderRadius: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {renderPreview}
      {!hasFile && renderPlaceholder}
    </Box>
  );

  return (
    <>
      {placeholder && (
        <InputLabel
          sx={{
            px: 1,
            fontSize: '0.8rem',
            fontFamily: 'Public Sans, sans-serif',
            fontWeight: 600,
          }}
        >
          {placeholder}
        </InputLabel>
      )}
      <Box
        {...getRootProps()}
        sx={{
          p: 0,
          width: 1,
          height: '53px',
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: 1,
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            borderColor: 'error.main',
          }),
          ...(hasFile && {
            ...(hasError && {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            }),
            '&:hover .upload-placeholder': {
              opacity: 1,
            },
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />
        {loading && (
          <Stack width={1} height={1} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
        {!loading && renderContent}
      </Box>

      {helperText && (
        <Typography
          variant="caption"
          sx={{
            width: 1,
            textAlign: 'start',
            color: 'text.disabled',
            mb: 2,
          }}
        >
          {helperText}
        </Typography>
      )}

      <RejectionFiles fileRejections={fileRejections} maxSize={maxSize} />
    </>
  );
}
