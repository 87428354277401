/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect} from 'react';
import {useDataContext} from '../../components/data';
import {getIntegrationOptions} from '../../services/integrations';

export default function IntegrationPopover() {
  const { addIntegrationOptions } = useDataContext();

  const getIntegrationOptionsList = useCallback(() => {
    getIntegrationOptions().then((integrations) => {
      addIntegrationOptions(integrations);
    });
  }, []);

  useEffect(() => {
    getIntegrationOptionsList();
  }, []);

  return <></>;
}
